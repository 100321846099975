export const pillsTabType = {
  Events: 'Events',
  Latest: 'Latest',
  Products: 'Products',
  Webinars: 'Webinars',
  Research: 'Research',
  Tutorials: 'Tutorials',
} as const

const pillsTabLabels = {
  latest: 'latest',
  products: 'products',
  webinars: 'webinars',
  events: 'events',
  research: 'research',
  tutorials: 'tutorials',
}

export const pills: {
  [key in PillTabsTypes]: {
    label: keyof typeof pillsTabLabels
    href?: string
  }
} = {
  Latest: {
    label: 'latest',
  },
  Products: {
    label: 'products',
    href: '/funds-pre-trade',
  },
  Webinars: {
    label: 'webinars',
    href: 'events/webinars',
  },
  Events: {
    label: 'events',
    href: 'events/in-person',
  },
  Research: {
    label: 'research',
    href: 'research/all-research',
  },
  Tutorials: {
    label: 'tutorials',
    href: 'help/tutorials',
  },
}

export type PillTabsTypes = keyof typeof pillsTabType
export const pillLabels = Object.keys(pills) as PillTabsTypes[]
export const pillLabelsArray = pillLabels.map((key) => pills[key].label)
