export const BACKGROUNDS = [
  'bg-neutral-0',
  'bg-neutral-100',
  undefined,
] as const

export const LAYOUTS = ['2col'] as const
export const DEFAULT_LAYOUT: LAYOUT = '2col'
export const DEFAULT_BACKGROUND: BACKGROUND = 'bg-neutral-100'

export type BACKGROUND = (typeof BACKGROUNDS)[number]
export type LAYOUT = (typeof LAYOUTS)[number]
