import type { PropsWithChildren, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { P, match } from 'ts-pattern'

import { ButtonLink } from '@cais-group/equity/atoms/button'
import { Skeleton, SkeletonAnimate } from '@cais-group/homepage/ui/skeleton'
import { CloudinaryImage, isSvg } from '@cais-group/shared/ui/cloudinary-image'
import { OwnershipTag } from '@cais-group/shared/ui/contentful/card'
import {
  contentContainer,
  PageErrorSplash,
} from '@cais-group/shared/ui/contentful/components'
import { CallToActionFieldsFragment } from '@cais-group/shared/util/graphql/mfe-contentful'

import { usePrepareInPersonEvents } from './hooks/use-prepare-in-person-events'

export const CaisLiveCallToAction = () => {
  const { pathname } = useLocation()
  const { callToAction } = usePrepareInPersonEvents()
  const { pageData, error, loading } = callToAction

  return match({ pageData, error, loading })
    .with({ error: true }, () => (
      <div className="pt-56">
        <PageErrorSplash.Inline />
      </div>
    ))
    .with({ error: false, loading: true }, () => (
      <CallToActionContainer label="Loading">
        <SkeletonAnimate className="mx-auto w-full max-w-prose space-y-32 *:mx-auto [&>:nth-child(4)]:!mt-16">
          <Skeleton height={32} width="20%" />
          <Skeleton height={48} width="55%" variant="rectangular" />
          <Skeleton height={24} width="95%" />
          <Skeleton height={24} width="95%" />
          <Skeleton height={48} width={120} />
        </SkeletonAnimate>
      </CallToActionContainer>
    ))
    .with({ error: false, pageData: P.not(P.nullish) }, ({ pageData }) => {
      return (
        <CallToActionContainer
          image={
            <BannerImage
              imageSet={pageData.imageSet}
              altText={pageData.title || ''}
            />
          }
          name={pageData.byCais ? '' : pageData.firm?.name ?? ''}
        >
          <h2 className="headline-m-strong md:headline-xxl-strong text-balance">
            {pageData.title}
          </h2>
          <p className="md:headline-s body max-w-prose">
            {pageData.description}
          </p>
          <div className="pt-16">
            <ButtonLink
              variant="primary"
              endAdornment="Launch"
              href={pageData.destination?.externalLink || pathname}
              openInNewWindow={Boolean(pageData.destination?.openInNewWindow)}
              tracking={{
                click_type: 'Content',
                content_type: 'Event',
                sub_section: `${pageData.title} CTA`,
              }}
            >
              {pageData.buttonText}
            </ButtonLink>
          </div>
        </CallToActionContainer>
      )
    })
    .otherwise(() => null)
}

function CallToActionContainer(
  props: PropsWithChildren<{ image?: ReactNode; label?: string; name?: string }>
) {
  const sectionLabel = 'CAIS in person events'
  return (
    <section
      aria-label={props.label ? `${props.label} ${sectionLabel}` : sectionLabel}
      className="md:py-88 space-y-16 bg-neutral-100 py-32 *:mx-auto"
    >
      {props.name ? (
        <div className="w-fit">
          <OwnershipTag name={props.name} />
        </div>
      ) : null}
      {props.image ?? null}
      <div
        className={`${contentContainer} flex flex-col items-center space-y-16 *:text-center md:space-y-32`}
      >
        {props.children}
      </div>
    </section>
  )
}

type BannerImageProps = {
  imageSet?: CallToActionFieldsFragment['imageSet']
  altText?: string
}

function BannerImage(props: BannerImageProps) {
  let imageType: 'img' | 'cloudinary' | null = null
  const image = Array.isArray(props.imageSet) ? props.imageSet[0] : null
  const aspectRatio = (image?.width ?? 1) / (image?.height ?? 1)
  const maxImageHeight = 45

  if (image && (image.public_id || image.secure_url)) {
    imageType = isSvg(image?.secure_url) ? 'img' : 'cloudinary'
  }

  if (imageType === 'img') {
    return (
      <img
        src={image.secure_url.replace('/f_auto', '')}
        height={maxImageHeight}
        alt={props.altText}
        width={maxImageHeight * aspectRatio}
      />
    )
  }

  if (imageType === 'cloudinary') {
    return (
      <CloudinaryImage
        src={image.public_id}
        layout="constrained"
        width={image.width}
        aspectRatio={aspectRatio}
        alt={props.altText}
        background="none"
        style={{ maxWidth: maxImageHeight * aspectRatio }}
        priority
      />
    )
  }

  return null
}
