import { ButtonLink } from '@cais-group/equity/atoms/button'
import { WaveBanner } from '@cais-group/equity/atoms/wave'
import { iconTypes } from '@cais-group/equity/particles/icons'
import { prepareLinkProps } from '@cais-group/homepage/util/common'
import {
  Card,
  CardProps,
  TextCard,
  prepareBasicCardProps,
} from '@cais-group/shared/ui/contentful/card'
import { Carousel } from '@cais-group/shared/ui/contentful/components'
import type { ContentDataType } from '@cais-group/shared/util/contentful/types'
import { CallToAction } from '@cais-group/shared/util/graphql/mfe-contentful'

import { DEFAULT_BACKGROUND, DEFAULT_LAYOUT } from '../constants'
import { TextCardProps, prepareTextComponentCardProps } from '../helper'
import { ComponentSectionProps } from '../types'

import { CollapsibleSectionContainer } from './collapsible-section-container'
import { SectionHeader } from './section-header'
import { TextContainer } from './text-container'

export function ComponentSection(props: ComponentSectionProps) {
  const {
    componentId,
    componentSectionDescription,
    customContent,
    itemsCollection,
    title,
  } = props
  const items = itemsCollection?.items

  /**
   * Note: Find the Custom-Content first and give it more control.
   * May need to lift this to the parent component to give it full control.
   * */

  return (() => {
    switch (componentId) {
      case 'Custom-Content-1':
      case 'Custom-Content-2':
      case 'Custom-Content-3':
      case 'Custom-Content-4':
      case 'Sticky-Download': {
        const Component = customContent?.[componentId]
        return Component ? (
          <Component
            description={componentSectionDescription}
            items={items}
            title={title}
          />
        ) : null
      }
      default:
        return items ? (
          <>
            <SectionHeader
              title={title}
              componentSectionDescription={componentSectionDescription}
            />
            {(() => {
              switch (componentId) {
                case 'Accordions':
                  return <CollapsibleSectionContainer items={items} />
                case '2-col':
                  return (
                    <TextContainer
                      items={items}
                      options={{
                        layout: DEFAULT_LAYOUT,
                        backgroundColor: DEFAULT_BACKGROUND,
                      }}
                    />
                  )
                case 'Carousel': {
                  return (
                    <Carousel
                      items={items}
                      // TODO HP-431 - Is there a better way to handle setting size of card?
                      itemMinWidth={items.length > 3 ? 'medium' : 'large'}
                      renderItem={({ item }) => {
                        if (item?.__typename === 'ComponentText') {
                          const props = prepareTextComponentCardProps({
                            ...item,
                            endAdornment: iconTypes.ArrowRight,
                          } as TextCardProps)
                          return <TextCard {...props} variant="text" />
                        } else {
                          const props = item
                            ? prepareBasicCardProps<CardProps>(
                                item as ContentDataType
                              )
                            : null
                          return props ? (
                            <Card {...props} date={undefined} />
                          ) : null
                        }
                      }}
                      testId="landing-page"
                    />
                  )
                }
                case '1-col':
                  return (
                    <TextContainer
                      items={items}
                      options={{
                        layout: '1col',
                        backgroundColor: DEFAULT_BACKGROUND,
                      }}
                    />
                  )
                case 'Wave-Banner': {
                  const [item] = items ?? []
                  const cta = item as CallToAction
                  if (!cta) return null
                  const buttonLink = prepareLinkProps(cta.destination)
                  return (
                    <WaveBanner
                      icon={null}
                      callToAction={
                        <ButtonLink
                          href={`${buttonLink?.href}`}
                          openInNewWindow={buttonLink?.openInNewWindow}
                          tracking={{
                            content_type: 'Page',
                            click_type: 'Content',
                            item_name: cta.buttonText || buttonLink?.text,
                            sub_section: title || '',
                          }}
                          inverse
                        >
                          {cta.buttonText || buttonLink?.text}
                        </ButtonLink>
                      }
                    >
                      <h3 className="headline-s-strong">{cta.title}</h3>
                      <p className="body-small pt-4 text-neutral-300">
                        {cta.description}
                      </p>
                    </WaveBanner>
                  )
                }
                default: {
                  return null
                }
              }
            })()}
          </>
        ) : null
    }
  })()
}
