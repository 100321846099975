import { ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { noop } from 'ts-essentials'

import { Icon } from '@cais-group/equity/atoms/icon'

type BreadcrumbClickHandler = (e: React.MouseEvent, path?: string) => void

export type BreadcrumbItem = {
  label: string
  path: string
  onClick?: BreadcrumbClickHandler
  isLink?: boolean
}

export interface BreadcrumbsProps {
  /** An array of objects representing each breadcrumb item. Each object must have a `label` and a `path`. */
  items: Array<BreadcrumbItem>
}

/**
 * Component for an individual breadcrumb.
 *
 * @component
 * @param to - The path the breadcrumb should link to.
 * @param onClick - Handler to use when you need to trigger
 * @param label - The label to display for the breadcrumb.
 * @param ariaLabel - The aria-label attribute for accessibility.
 * @param testId - The data-testid attribute for testing.
 * @param children - The content to display inside the breadcrumb.
 * @param isLink - Dictates whether a link or a span is used in case we want to prevent navigating when clicking on the breadcrumb. Defaults to true
 */
const Breadcrumb = ({
  to,
  testId,
  children,
  onClick,
  isLink = true,
}: LinkProps & {
  label: string
  testId?: string
  children: ReactNode
  onClick?: BreadcrumbClickHandler
  isLink?: boolean
}) => {
  if (!isLink) {
    return (
      <span
        onClick={(e) => (onClick ? onClick(e, to.toString()) : noop)}
        className="small-strong text-primary-600 cursor-pointer"
        data-testid={testId}
      >
        {children}
      </span>
    )
  }

  return (
    <Link
      to={to}
      onClick={(e) => (onClick ? onClick(e, to.toString()) : noop)}
      className="small-strong text-primary-600"
      data-testid={testId}
    >
      {children}
    </Link>
  )
}

/**
 * Breadcrumbs are a series of links that help users understand where they are within the hierarchy of CAIS.
 * They provide a path for navigation and allow users to move up to any of its "ancestors".
 * This component uses `react-router-dom` to provide breadcrumb functionality and therefore requires a Router higher-order component (HOC) to be wrapped around it.
 *
 * @component
 * @param {BreadcrumbItem[]} props.items - An array of objects representing each breadcrumb item. Each object must have a `label` and a `path`.
 */
export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  if (!items.length) {
    return null
  }

  const currentPage = items[items.length - 1]
  const breadcrumbs = items.slice(0, items.length - 1)
  const breadcrumbLimit = 5 // Maximum number of breadcrumbs to display

  return (
    <nav aria-label="Navigation Breadcrumbs">
      <ol className="flex items-center gap-x-8">
        {breadcrumbs.slice(0, breadcrumbLimit + 1).map((item) => (
          <li key={item.label} className="flex items-center gap-x-8">
            <Breadcrumb
              to={item.path}
              label={item.label}
              onClick={item.onClick}
              isLink={item.isLink}
            >
              {item.label}
            </Breadcrumb>
            <Icon
              color="eq-color-neutral-300"
              size="small"
              type="ArrowForwardLrg"
              aria-hidden={true}
            />
          </li>
        ))}
        <li className="small flex items-center text-neutral-900">
          {currentPage.label}
        </li>
      </ol>
    </nav>
  )
}
