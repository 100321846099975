import { ButtonLink } from '@cais-group/equity/atoms/button'
import { StatusTag } from '@cais-group/equity/atoms/status-tag'
import { callToActionEventsLink } from '@cais-group/homepage/util/common'
import {
  DateTime,
  OwnershipTag,
  getContentOwner,
  getContentOwnershipTag,
} from '@cais-group/shared/ui/contentful/card'
import type {
  EventType,
  EventStatusKey,
} from '@cais-group/shared/util/contentful/types'

type StatusLabel = 'Almost Full' | 'Coming Soon' | 'Full' | 'Open' | 'Upcoming'
type StatusColor = 'error' | 'neutral' | 'success' | 'warning'
type StatusCta = 'Coming Soon' | 'Register' | 'Read More'
type StatusProps = Record<
  EventStatusKey,
  { label: StatusLabel; color: StatusColor; cta: StatusCta }
>

const statusProps: StatusProps = {
  upcoming: {
    label: 'Upcoming',
    color: 'neutral',
    cta: 'Coming Soon',
  },
  open: {
    label: 'Open',
    color: 'success',
    cta: 'Register',
  },
  'almost-full': {
    label: 'Almost Full',
    color: 'warning',
    cta: 'Register',
  },
  full: {
    label: 'Full',
    color: 'error',
    cta: 'Read More',
  },
}

export function LiveEventItem(props: { event: EventType }) {
  const { event } = props
  const status = statusProps[event.status as EventStatusKey]
  const link = callToActionEventsLink(
    event.status as EventStatusKey,
    event.link
  )
  const contentOwnershipTag = getContentOwnershipTag(event)

  return (
    <dl className="flex w-full flex-wrap gap-8">
      <dt className="sr-only">Location</dt>
      <dd className="body-strong after:ml-8 after:inline-block after:content-['•']">
        {event.locationName}
      </dd>
      <dt className="sr-only">Date</dt>
      <dd>
        <DateTime date={event.date} />
      </dd>
      {contentOwnershipTag?.name ? (
        <>
          <dt className="sr-only">Hosted by</dt>
          <dd>
            <OwnershipTag name={contentOwnershipTag.name} />
          </dd>
        </>
      ) : null}

      {status ? (
        <>
          <dt className="sr-only">Registration status</dt>
          <dd className="basis-full md:basis-auto">
            <StatusTag color={status.color} variant="light">
              {status.label}
            </StatusTag>
          </dd>
        </>
      ) : null}
      {link && status?.cta ? (
        <>
          <dt className="sr-only">More information</dt>
          <dd className="ml-auto [&>a]:h-fit">
            <ButtonLink
              variant="tertiary"
              endAdornment="Launch"
              href={link}
              tracking={{
                click_type: 'Content',
                content_type: 'Event',
                content_owner: getContentOwner(event),
                item_name: status.cta,
              }}
            >
              {status.cta}
            </ButtonLink>
          </dd>
        </>
      ) : null}
    </dl>
  )
}
