import type { SiteConfig } from '@cais-group/shared/util/contentful/types'

export const APP_URL = '/homepage'

export const HOMEPAGE_BFF = '/homepage-bff'
export const HOMEPAGE_BFF_PORT = 3217
export const IAM_API_URL = 'iam/v1'
export const FUND_MANAGER_API_URL = 'fund-products/v1'

export const SITE_CONFIG: SiteConfig = {
  pages: {
    PageResource: {
      path: '/resources',
      slug: '/[slug]',
    },
    CaisiqArticle: {
      path: '/articles',
      slug: '/[slug]',
    },
    Whitepaper: {
      path: '/whitepapers',
      slug: '/[slug]',
    },
  },
}
