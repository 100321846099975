import { P, match } from 'ts-pattern'

import {
  Divider,
  FeaturedSkeleton,
  SkeletonGrid,
} from '@cais-group/homepage/ui/components'
import {
  Card,
  CardFeatured,
  prepareBasicCardProps,
} from '@cais-group/shared/ui/contentful/card'
import type { FeaturedCardProps } from '@cais-group/shared/ui/contentful/card'
import { Section, AutoGrid } from '@cais-group/shared/ui/contentful/components'
import type { ContentDataType } from '@cais-group/shared/util/contentful/types'

import { HomepageNoResults } from '../../../components/homepage-no-results'

type CardProps = Parameters<typeof Card>[0]

type TutorialsProps = {
  featured: ContentDataType | null
  tutorials: ContentDataType[] | null
  debouncedSearchText: string
  loading: boolean
}

export function Tutorials(props: TutorialsProps) {
  const { featured, tutorials = [], debouncedSearchText, loading } = props
  const featuredCardProps = featured
    ? prepareBasicCardProps<FeaturedCardProps>(featured)
    : null

  const tutorialsLength = tutorials?.length

  const paddingSize =
    tutorials?.length && !featuredCardProps ? 'medium' : 'large'

  return (
    <>
      {match({ featuredCardProps, loading })
        .with(
          {
            loading: true,
          },
          () => <FeaturedSkeleton />
        )
        .with(
          {
            featuredCardProps: P.not(P.nullish),
          },
          ({ featuredCardProps }) => <CardFeatured {...featuredCardProps} />
        )
        .otherwise(() => null)}

      {match({
        tutorials,
        tutorialsLength,
        debouncedSearchText,
        featuredCardProps,
        loading,
      })
        .with(
          {
            loading: true,
          },
          () => (
            <Divider>
              <SkeletonGrid />
            </Divider>
          )
        )
        .with(
          {
            tutorialsLength: P.when((l) => !l),
            debouncedSearchText: P.when(
              (debouncedSearchText) => !!debouncedSearchText
            ),
            featuredCardProps: P.nullish,
            tutorials: P.not(P.nullish),
            loading: false,
          },
          () => <HomepageNoResults searchText={debouncedSearchText} />
        )
        .with(
          {
            tutorials: P.not(P.nullish),
            tutorialsLength: P.number.gt(0),
          },
          ({ tutorials }) => (
            <Divider paddingSize={paddingSize}>
              <Section title="">
                <AutoGrid>
                  {tutorials &&
                    tutorials.map((item, index) => {
                      const props = prepareBasicCardProps<CardProps>(item)
                      return props ? (
                        <Card
                          key={props.id}
                          {...props}
                          testId={`tutorials-${props.__typename}-${index}`}
                        />
                      ) : null
                    })}
                </AutoGrid>
              </Section>
            </Divider>
          )
        )
        .otherwise(() => null)}
    </>
  )
}
