import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  ContentPermissionsData,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import type { ContentDataType } from '@cais-group/shared/util/contentful/types'
import { useGetFeaturedResearchQuery } from '@cais-group/shared/util/graphql/mfe-contentful'
import type { GetFeaturedResearchQuery } from '@cais-group/shared/util/graphql/mfe-contentful'

import {
  FEATURED_CONTENT_APP_ID,
  FILTERS,
  FIRMS_COLLECTION_LIMIT,
} from '../constants'
import { selectFeaturedItem } from '../helper'

type Data = {
  featured: ContentDataType | null
} | null

export const useGetFeaturedResearch = () => {
  const allowedUserPermissions = useGetAllowedContentPermissions()

  const response = useReactQueryResultAsApiState<
    GetFeaturedResearchQuery,
    { featured: ContentDataType | null }
  >(
    useGetFeaturedResearchQuery(
      {
        featuredId: FEATURED_CONTENT_APP_ID,
        fallbackWhere: FILTERS.research.is,
        optionsWhere: FILTERS.research.is,
        preview: previewService.enabled,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      { refetchOnWindowFocus: false }
    ),
    (data) => selectFeaturedResearch(data, allowedUserPermissions.data),
    'Could not load featured research query'
  )

  const loading =
    response === ApiStateEnum.LOADING || allowedUserPermissions.isLoading
  const error = isError(response)

  return compileResults(response, loading, error)
}

const compileResults = (
  data: Data | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}

const selectFeaturedResearch = (
  data: GetFeaturedResearchQuery,
  allowedPermissionsData: ContentPermissionsData
) => {
  const featuredItems = data.featured?.items[0]
  const featured = selectFeaturedItem<ContentDataType>({
    options: featuredItems?.options?.items as unknown as ContentDataType[],
    fallback: featuredItems?.fallback?.items[0] as ContentDataType,
    userAccessData: allowedPermissionsData,
  })
  return {
    featured,
  }
}
