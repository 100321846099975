import { camelCase } from 'lodash-es'
import { type ReactNode } from 'react'
import { useRef } from 'react'
import { useMedia } from 'react-use'
import { match, P } from 'ts-pattern'

import {
  ViewAll,
  SkeletonCardList,
  FeaturedSkeleton,
} from '@cais-group/homepage/ui/components'
import { ContentDisclaimer } from '@cais-group/homepage/ui/content-disclaimer'
import { CuratedContent } from '@cais-group/homepage/ui/layout'
import {
  Card,
  CardFeatured,
  isEvent,
  prepareBasicCardProps,
  prepareEventCardProps,
  preparePressReleaseCardProps,
} from '@cais-group/shared/ui/contentful/card'
import type {
  CardProps,
  FeaturedCardProps,
} from '@cais-group/shared/ui/contentful/card'
import {
  Carousel,
  contentContainer,
  Section,
} from '@cais-group/shared/ui/contentful/components'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import {
  type ContentDataType,
  type CuratedContentDataType,
  type HomeContentType,
  type PressReleaseType,
} from '@cais-group/shared/util/contentful/types'

import { CarouselSplash } from '../../components/carousel-no-results'
import { PillTabs } from '../../components/pill-tabs'
import { SkeletonHeader } from '../../components/skeleton.header'
import {
  pillLabels,
  pillLabelsArray,
  pills,
  type PillTabsTypes,
} from '../../utils/pill-config'

type HomeProps = {
  featured: ContentDataType | null
  press: {
    pressIndexLink: string
    pressReleases: PressReleaseType[] | null
    error: boolean
  }
  browseContent: HomeContentType & {
    loading: boolean
    error: boolean
  }
  activePillTab: PillTabsTypes
  setActivePillTab: (label: PillTabsTypes) => void
  featureLoading: boolean
  featuredError: boolean
  curatedContent: {
    data: CuratedContentDataType[] | null
    error: boolean
    loading: boolean
  }
  quickLinks?: ReactNode
}

const VIEW_ALL_TEXT = `View All`

export const Home = (props: HomeProps) => {
  const {
    featured,
    press,
    browseContent,
    activePillTab,
    setActivePillTab,
    featureLoading,
    featuredError,
    curatedContent,
    quickLinks,
  } = props
  const ref = useRef<HTMLUListElement>(null)
  const isNotMobile = useMedia(`(min-width: ${BREAKPOINTS.breakpointSm})`)

  const featuredCardProps = featured
    ? prepareBasicCardProps<FeaturedCardProps>(featured)
    : null

  const { pressReleases, pressIndexLink, error: pressReleasesError } = press
  const {
    data: curatedData,
    loading: curatedLoading,
    error: curatedError,
  } = curatedContent

  const handlePillClick = (activeTab: PillTabsTypes) => {
    setActivePillTab(activeTab)
    const activePill = document.querySelector(
      `#${camelCase(activeTab)}`
    ) as HTMLElement
    if (!activePill || !ref.current || isNotMobile) return

    const ulCenter = ref.current.offsetWidth / 2
    const pillCenter = activePill.offsetLeft + activePill.offsetWidth / 2
    const scrollDistance = pillCenter - ulCenter

    ref.current.scrollTo({ left: scrollDistance, behavior: 'smooth' })
  }
  const { loading, error: allCarouselContentError } = browseContent

  const allContentItems = [
    ...pillLabelsArray.flatMap((key) => browseContent[key].carousel),
    ...(curatedData ?? []),
    featured ?? ({} as ContentDataType),
  ]

  return (
    <div
      className={`${contentContainer} grid grid-cols-1 gap-32 pb-56 lg:grid-cols-[1fr_2fr]`}
    >
      {/* Column 1 */}
      <div
        className="mb-0 space-y-32 overflow-hidden lg:mb-0"
        aria-label="resources-section"
      >
        {quickLinks}
        {match({ curatedData, curatedLoading, curatedError })
          .with(
            {
              curatedData: P.not(P.nullish),
              curatedLoading: false,
              curatedError: false,
            },
            ({ curatedData }) => {
              return <CuratedContent cards={curatedData} />
            }
          )
          .otherwise(() => null)}
      </div>

      {/* Column 2 */}
      <div
        className="@container/main-column lg:overflow-hidden [&>section:not(:first-child,:last-child)]:mb-32"
        aria-label="main-content-section"
      >
        {match({ featuredError, featureLoading, featuredCardProps })
          .with({ featureLoading: true, featuredError: false }, () => (
            <Section sectionId="Featured Content" withPadding>
              <FeaturedSkeleton compact flip="horizontal" testId="homepage" />
            </Section>
          ))
          .with(
            { featuredError: false, featuredCardProps: P.not(P.nullish) },
            ({ featuredCardProps }) => (
              <Section sectionId="Featured Content" withPadding>
                <CardFeatured
                  compact
                  flip="horizontal"
                  {...featuredCardProps}
                />
              </Section>
            )
          )
          .otherwise(() => null)}

        {[browseContent].map((content) => {
          const data = content[pills[activePillTab].label].carousel
          const length = content[pills[activePillTab].label].carousel.length
          const error = content[pills[activePillTab].label].error
          const viewAllText = `${VIEW_ALL_TEXT} ${activePillTab}`

          const showViewAll =
            (content[pills[activePillTab].label].carousel.length || !error) &&
            pills[activePillTab].href

          return (
            // conditional rendering of the section title && controls based on loading state to get rid of repeated code
            <Section
              title={!loading ? 'Browse Content' : ''}
              key={pills[activePillTab].label}
              endAdornment={
                showViewAll ? (
                  <ViewAll
                    href={pills[activePillTab].href as string}
                    text={viewAllText}
                  />
                ) : null
              }
              controls={
                <PillTabs
                  onHandlePillClick={handlePillClick}
                  activePillTab={activePillTab}
                  pillLabels={pillLabels}
                  pillRef={ref}
                />
              }
              withPadding
            >
              {match({ error, data, length, loading })
                .with({ loading: true, error: false }, () => (
                  <SkeletonCardList
                    testId="browse-content"
                    key="browse-content-skeleton"
                    skeletonControls={
                      <SkeletonHeader title="Browse Content" type="tabs" />
                    }
                  />
                ))
                .with({ error: true }, () => <CarouselSplash.Error />)
                .with({ length: 0 }, () => (
                  <CarouselSplash.NoResults title="Content not yet available" />
                ))
                .with({ error: false, data: P.not(P.nullish) }, ({ data }) => (
                  <Carousel
                    items={data}
                    itemsPerPage={3}
                    key={pills[activePillTab].label}
                    itemMinWidth="small"
                    minHeight="400px"
                    renderItem={({ item }) => {
                      const props = isEvent(item)
                        ? prepareEventCardProps<CardProps>(item)
                        : prepareBasicCardProps<CardProps>(item)

                      return props ? (
                        <Card {...props} testId="browse-content" />
                      ) : null
                    }}
                    testId="browse-content"
                  />
                ))
                .otherwise(() => null)}
            </Section>
          )
        })}

        {match({ allContentItems })
          .with(
            {
              allContentItems: P.not(P.nullish),
            },
            ({ allContentItems }) => {
              return <ContentDisclaimer items={allContentItems} />
            }
          )
          .otherwise(() => null)}

        {match({
          allCarouselContentError,
          pressReleasesError,
          pressReleases,
          loading,
        })
          .with({ pressReleasesError: true }, () => (
            <Section
              title="Press Releases"
              endAdornment={
                <ViewAll href={pressIndexLink} text={VIEW_ALL_TEXT} />
              }
            >
              <CarouselSplash.Error />
            </Section>
          ))
          .with(
            {
              loading: true,
              pressReleasesError: false,
              allCarouselContentError: false,
            },
            () => (
              <Section withPadding>
                <SkeletonCardList
                  testId="press-releases"
                  key="press-releases-skeleton"
                  skeletonControls={<SkeletonHeader title="Press Releases" />}
                />
              </Section>
            )
          )
          .with(
            {
              pressReleasesError: false,
              pressReleases: P.not(P.nullish),
            },
            ({ pressReleases }) => (
              <Section
                title="Press Releases"
                endAdornment={
                  pressReleases &&
                  !allCarouselContentError && (
                    <ViewAll href={pressIndexLink} text={VIEW_ALL_TEXT} />
                  )
                }
                withPadding
              >
                <Carousel
                  items={pressReleases}
                  itemsPerPage={3}
                  itemMinWidth="small"
                  renderItem={({ item }) => {
                    const props = preparePressReleaseCardProps<CardProps>(
                      item,
                      pressIndexLink
                    )
                    return props ? (
                      <Card {...props} testId="press-release" />
                    ) : null
                  }}
                />
              </Section>
            )
          )
          .otherwise(() => null)}
      </div>
    </div>
  )
}
