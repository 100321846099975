import { ContentDataType } from '@cais-group/shared/util/contentful/types'

import { CAROUSEL_ITEM_LIMIT } from '../constants'

/**
 *
 * @param data Selects one of each item from the data array until the carousel item limit is reached
 * @returns An array of content items
 */
export const selectLatestContent = (data: ContentDataType[][]) => {
  const latest: ContentDataType[] = []
  let i = 0

  // The limit prevents a potential infinite loop if for some reason the user
  // doesn't have enough items to fill the carousel
  const maxAvailableToUser = data.reduce((acc, curr) => acc + curr.length, 0)

  while (
    (latest.length < CAROUSEL_ITEM_LIMIT &&
      latest.length < maxAvailableToUser) ||
    (i < maxAvailableToUser && latest.length < CAROUSEL_ITEM_LIMIT)
  ) {
    const currentLatest = data.reduce((acc, curr) => {
      const item = i < curr.length ? curr[i] : null

      if (item) {
        acc.push(item)
      }

      return acc
    }, [])

    latest.push(...currentLatest)
    i++
  }

  return latest
}
