import { useEffect } from 'react'

import { getEnvConfig as getCiqEnvConfig } from '@cais-group/caisiq-ui-env'
import { ContentDisclaimer } from '@cais-group/homepage/ui/content-disclaimer'
import { getContentOwnershipTag } from '@cais-group/shared/ui/contentful/card'
import { FrontMatter } from '@cais-group/shared/ui/contentful/common/front-matter'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import { getEnvConfig as getMfeEnvConfig } from '@cais-group/shared/ui/env'
import type { ContentDataType } from '@cais-group/shared/util/contentful/types'
import { isBlankString } from '@cais-group/shared/util/js/common'
import {
  millisecondsToMinutes,
  secondsToMinutes,
} from '@cais-group/shared/util/js/format-length'
import { TypeVideoData } from '@cais-group/shared/util/type/video-data'

const SEGMENT_PAGE_ID = 'video'

function removeHtmlTags(html?: string) {
  return html ? html.replace(/(<([^>]+)>)/gi, '') : ''
}

export const VideoPageFrontmatter = ({
  video,
  episodic,
  episodeNumber,
}: {
  video?: TypeVideoData
  episodic?: boolean | null
  episodeNumber?: number
}) => {
  const eyebrow = episodic ? `Episode ${episodeNumber}` : 'Video'
  const hasRichText = video?.body && video?.body?.json
  const hasSummary = !isBlankString(video?.summary)
  const duration = video?.muxVideo?.duration
    ? secondsToMinutes(video?.muxVideo?.duration, 'round')
    : millisecondsToMinutes(video?.duration ?? 0, 'round')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!video) return null

  return (
    <>
      <div className="w-full max-w-prose space-y-32 pt-32 has-[[data-component='content-disclaimer']]:pb-56">
        <FrontMatter
          {...{
            date: video.displayDate ?? video.sys?.firstPublishedAt ?? '',
            duration,
            eyebrow,
            title: video.title ?? '',
            contentOwnershipTag: getContentOwnershipTag(
              video as ContentDataType
            ),
          }}
        />
        <ContentDisclaimer
          items={[video as ContentDataType]}
          envConfig={getMfeEnvConfig() || getCiqEnvConfig()}
        />
      </div>
      <div className="pb-88 w-full max-w-prose space-y-24">
        {hasSummary && (
          <p className="body !mt-0 whitespace-pre-line">
            {removeHtmlTags(video?.summary || '')}
          </p>
        )}
        {hasRichText && (
          <RichText field={video.body} segmentPageId={SEGMENT_PAGE_ID} />
        )}
      </div>
    </>
  )
}
