import { type ReactNode } from 'react'

import { BrandIcon } from '@cais-group/equity/atoms/icon'
import type { BrandIconType } from '@cais-group/equity/particles/icons'
import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import { contentContainer } from '@cais-group/shared/ui/contentful/components'
import { getUrlToSectionMap } from '@cais-group/shared/util/segment'

type PageHeaderProps = {
  title: string
  icon?: BrandIconType
  search?: ReactNode | null
  borderBottom?: boolean
}

export function PageHeader(props: PageHeaderProps) {
  const { icon, search, title, borderBottom = true } = props

  return (
    <header
      role="banner"
      {...mapTrackingKeysToDataAttributes({
        section: getUrlToSectionMap('homepage'),
      })}
      className={`${
        borderBottom ? 'border-b-1' : 'border-b-0'
      } bg-neutral-0 shadow-2 border-0 border-solid border-neutral-200`}
    >
      <div
        className={`${contentContainer} flex flex-wrap items-center gap-24 py-24`}
      >
        <h1 className="headline-s-strong mr-auto flex items-center gap-16">
          {icon ? <BrandIcon type={icon} /> : null} {title}
        </h1>
        {search ? search : null}
      </div>
    </header>
  )
}
