import { useEffect, useReducer } from 'react'

import { Button } from '@cais-group/equity/atoms/button'
import { type Tracking } from '@cais-group/equity/util/tracking-utils'
import type { ContentDataType } from '@cais-group/shared/util/contentful/types'

const DEFAULT_PAGE_SIZE = 12
const VIEW_MORE_TEXT = 'View More'

type ReducerState = {
  hasNextPage: boolean
  isFetching: boolean
  page: number
  take: number
  total: number
}
type ReducerAction = {
  type: 'fetching' | 'next' | 'ready' | 'update'
  payload?: Partial<ReducerState>
}

function createReducer(pageSize: number) {
  return function reducer(currentState: ReducerState, action: ReducerAction) {
    if (action.type === 'next') {
      const nextPage = currentState.page + 1
      const nextTake = nextPage * pageSize
      return {
        ...currentState,
        page: nextPage,
        take: nextTake,
        hasNextPage: currentState.total > nextTake,
        isFetching: false,
      }
    }
    if (action.type === 'fetching') {
      return {
        ...currentState,
        isFetching: true,
      }
    }
    if (action.type === 'update') {
      return {
        ...currentState,
        ...(action.payload || {}),
      }
    }
    return currentState
  }
}

type ReducerDispatch = ReturnType<typeof useReducer>[1]

export function usePagination(
  items: ContentDataType[],
  pageSize = DEFAULT_PAGE_SIZE
) {
  const [state, dispatch] = useReducer(createReducer(pageSize), {
    page: 1,
    take: pageSize,
    total: items.length,
    hasNextPage: items.length > pageSize,
    isFetching: false,
  })

  useEffect(() => {
    const total = items.length
    dispatch({
      type: 'update',
      payload: {
        hasNextPage: total > pageSize,
        total,
      },
    })
  }, [items.length, pageSize])

  return {
    reducer: { dispatch, state },
    pageSize,
    ViewMore: {
      Component: ViewMore,
      label: VIEW_MORE_TEXT,
    },
  }
}

type ViewMoreProps = Tracking & {
  reducer: {
    dispatch: ReducerDispatch
    state: ReducerState
  }
}

export function ViewMore(props: ViewMoreProps) {
  const { reducer } = props
  return reducer?.state.hasNextPage ? (
    <div className="flex justify-center py-24">
      <Button
        variant="tertiary"
        size="medium"
        onClick={async () => {
          reducer.dispatch({ type: 'fetching' })
          setTimeout(() => reducer.dispatch({ type: 'next' }), 250)
        }}
        loading={reducer.state.isFetching}
        disabled={reducer.state.isFetching}
        tracking={{
          click_type: 'Information Expansion',
          item_name: VIEW_MORE_TEXT,
          ...props.tracking,
        }}
      >
        {reducer.state.isFetching ? 'Loading...' : VIEW_MORE_TEXT}
      </Button>
    </div>
  ) : null
}
