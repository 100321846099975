import { P, match } from 'ts-pattern'

import { Divider, SkeletonGrid } from '@cais-group/homepage/ui/components'
import { usePagination } from '@cais-group/homepage/util/hook/use-pagination'
import { trackingLabels } from '@cais-group/homepage/util/tracking'
import {
  Card,
  prepareBasicCardProps,
} from '@cais-group/shared/ui/contentful/card'
import { AutoGrid } from '@cais-group/shared/ui/contentful/components'
import { ContentDataType } from '@cais-group/shared/util/contentful/types'

import { HomepageNoResults } from '../../components/homepage-no-results'

type CardProps = Parameters<typeof Card>[0]

// TODO HP- move prepare functions into query
interface AllResearchProps {
  items: ContentDataType[]
  searchText: string
  loading: boolean
}

export function AllResearch(props: AllResearchProps) {
  const { items, searchText, loading } = props
  const { reducer, pageSize, ViewMore } = usePagination(items)
  const state = reducer.state
  const total = state.total

  return match({ total, loading })
    .with(
      {
        loading: true,
      },
      () => (
        <Divider paddingSize="none">
          <SkeletonGrid />
        </Divider>
      )
    )
    .with(
      {
        total: P.when((l) => !l),
        loading: false,
      },
      () => <HomepageNoResults searchText={searchText} addPadding />
    )
    .with(
      {
        total: P.when((l) => l > 0),
        loading: false,
      },
      () => (
        <Divider paddingSize="none">
          <AutoGrid>
            {items.slice(0, state.take).map((item, index) => {
              const props = item ? prepareBasicCardProps<CardProps>(item) : null
              const animationDelay =
                state.page > 1 && index - pageSize * (state.page - 1) > -1
                  ? (index % pageSize) * 25 + 1
                  : 0
              return props ? (
                <Card
                  key={props.id}
                  {...props}
                  tracking={{
                    sub_section: trackingLabels.subSection.GridOfContent,
                  }}
                  testId={`research-${item?.__typename}-${index}`}
                  animate={animationDelay > 0}
                  style={{ '--delay': `${animationDelay}ms` }}
                />
              ) : null
            })}
          </AutoGrid>

          <ViewMore.Component
            reducer={reducer}
            tracking={{ sub_section: trackingLabels.subSection.GridOfContent }}
          />
        </Divider>
      )
    )
    .otherwise(() => null)
}
