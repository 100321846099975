import {
  NavigationTabs,
  type NavigationTabItem,
} from '@cais-group/equity/molecules/tabs'
import { contentContainer } from '@cais-group/shared/ui/contentful/components'

type HomepageTabsNavBarProps = {
  tabs: NavigationTabItem[]
  label?: string
}

export function HomepageTabsNavBar({
  label = 'secondary navigation',
  tabs,
}: HomepageTabsNavBarProps) {
  return (
    <nav aria-label={label} className="shadow-2 bg-neutral-0 relative z-10">
      <div className={contentContainer}>
        <NavigationTabs items={tabs} />
      </div>
    </nav>
  )
}

export default HomepageTabsNavBar
