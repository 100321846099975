import { useParams } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import type { BrandIconType } from '@cais-group/equity/particles/icons'
import { PageHeader } from '@cais-group/homepage/ui/page-header'
import { useGetHomepageLandingPage } from '@cais-group/shared/domain/contentful/landing-page'
import {
  PageErrorSplash,
  Main,
} from '@cais-group/shared/ui/contentful/components'
import { Disclaimer } from '@cais-group/shared/ui/contentful/disclaimer'
import { DocumentTitle } from '@cais-group/shared/util/document-title'
import { LandingPageComponents } from '@cais-group/shared-ui-contentful-landing-page-components-manager'

export function LandingPage() {
  const params = useParams()
  const { pageData, error, loading } = useGetHomepageLandingPage({
    slug: params['*'] || '',
  })

  return (
    <>
      <Main fullWidth>
        {match({ pageData, error, loading })
          .with({ error: true }, () => <PageErrorSplash.General />)
          .with({ loading: true, error: false }, () => (
            <LoadingContainer
              state={LoadingState.LOADING}
              type="large"
              coverPage="FULL_SCREEN_WITH_HEADER"
            />
          ))
          .with(
            { error: false, pageData: P.not(P.nullish) },
            ({ pageData }) => (
              <>
                <DocumentTitle title={pageData.title || ''} />
                <PageHeader
                  title={pageData?.title || ''}
                  icon={pageData?.icon as BrandIconType}
                />
                {pageData.sectionsCollection?.items?.map((section, idx) => {
                  return section ? (
                    <LandingPageComponents
                      key={`${section?.sys.id}-${idx}`}
                      section={section}
                    />
                  ) : null
                })}
              </>
            )
          )
          .otherwise(() => (
            <PageErrorSplash.NotFound />
          ))}
      </Main>
      {pageData?.disclaimer && (
        <Disclaimer
          title={pageData.disclaimer.title}
          body={pageData.disclaimer.body}
        />
      )}
    </>
  )
}
