import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { useGetArticleBySlug } from '@cais-group/homepage/domain/contentful'
import { APP_URL } from '@cais-group/homepage/util/common'
import { WithAugmentedPageTracking } from '@cais-group/homepage/util/tracking'
import { Article } from '@cais-group/shared/ui/contentful/article'
import { ContentfulBreadcrumbs as Breadcrumbs } from '@cais-group/shared/ui/contentful/breadcrumb'
import {
  Main,
  PageErrorSplash,
} from '@cais-group/shared/ui/contentful/components'
import { DocumentTitle } from '@cais-group/shared/util/document-title'

export function Articles() {
  const { slug } = useParams<{ slug: string }>()
  const { pageData, error, loading, existsWithNoAccess, notFound } =
    useGetArticleBySlug(slug)

  const getFundProductIds = useCallback(() => {
    return { fund_ids: pageData?.fundProductIds?.fundProductIds }
  }, [pageData?.fundProductIds])

  return (
    <>
      {match({ pageData, error, existsWithNoAccess, notFound })
        .with(
          {
            error: false,
            existsWithNoAccess: false,
            notFound: false,
            pageData: P.not(P.nullish),
          },
          ({ pageData }) => (
            <Breadcrumbs
              baseUrl={APP_URL}
              category={pageData?.category || ''}
              currentPage={pageData?.title}
            />
          )
        )
        .otherwise(() => null)}

      <Main fullWidth>
        {match({ pageData, error, loading, existsWithNoAccess, notFound })
          .with({ error: true }, () => <PageErrorSplash.General />)
          .with({ notFound: true, loading: false }, () => (
            <PageErrorSplash.NotReached />
          ))
          .with({ existsWithNoAccess: true, loading: false }, () => (
            <PageErrorSplash.NoAccess />
          ))
          .with({ loading: true, error: false }, () => (
            <LoadingContainer
              state={LoadingState.LOADING}
              type="large"
              coverPage="FULL_SCREEN_WITH_HEADER"
            />
          ))
          .with(
            { error: false, pageData: P.not(P.nullish) },
            ({ pageData }) => {
              return (
                <>
                  <DocumentTitle title={pageData.title} />
                  <WithAugmentedPageTracking getData={getFundProductIds} />
                  <Article {...pageData} urlPrefix={APP_URL} />
                </>
              )
            }
          )
          .otherwise(() => null)}
      </Main>
    </>
  )
}
