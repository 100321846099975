import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  ContentPermissionsData,
  parseFromCollection,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import type {
  ContentDataType,
  EventType,
} from '@cais-group/shared/util/contentful/types'
import {
  CaisiqVideoFilter,
  CaisiqVideoPlaylistFilter,
  GetWebinarsQueryVariables,
  useGetWebinarsQuery,
  useGetWebinarsReplayQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import type {
  GetWebinarsReplayQuery,
  GetWebinarsQuery,
  CaisiqVideo,
  CaisiqVideoPlaylist,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import {
  FILTERS,
  FEATURED_CONTENT_APP_ID,
  FIRMS_COLLECTION_LIMIT,
  TAGS_COLLECTION_LIMIT,
} from '../constants'
import {
  excludeBySysId,
  filterAllowedPermissionData,
  selectFeaturedItem,
  sortContentData,
} from '../helper'

/* NOTE
  useGetWebinars: a specific query for the Webinars page.
  useGetWebinarsReplay: to be used for the "view more" replays feature.
  More functions can be added to query other event types or conditions.
*/

type Data = {
  featured: EventType | null
  upcoming: EventType[]
  replays: ContentDataType[]
} | null

const eventType = 'Webinar'
const category = 'webinars'

type VariablesType = {
  date: string
  upcomingLimit?: number
  replaysLimit?: number
  searchText?: string
}
const makeWebinarQueryVariables = (
  variables: VariablesType
): GetWebinarsQueryVariables => {
  const { searchText, date, ...rest } = variables

  const whereTitle = {
    title_contains: searchText,
  }

  const isSearchUpcoming = searchText?.toLowerCase() === 'upcoming'
  const isSearchReplays = searchText?.toLowerCase().includes('replay')

  const commonOr = !isSearchUpcoming
    ? {
        OR: [
          whereTitle,
          {
            description_contains: searchText,
          },
        ],
      }
    : {}

  const videosOr: CaisiqVideoFilter | CaisiqVideoPlaylistFilter =
    !isSearchReplays
      ? {
          OR: [
            whereTitle,
            {
              summary_contains: searchText,
            },
          ],
        }
      : {}

  const videosWhere: CaisiqVideoFilter | CaisiqVideoPlaylistFilter = {
    AND: [FILTERS.webinars.is, videosOr, { displayDate_lte: date }],
  }

  return {
    preview: previewService.enabled,
    featuredId: FEATURED_CONTENT_APP_ID,
    upcomingWhere: {
      AND: [commonOr, { eventType }, { dateTime_gte: date }],
    },
    videosWhere,
    playlistWhere: videosWhere,
    includeFeatured: !searchText,
    firmsLimit: FIRMS_COLLECTION_LIMIT,
    tagsLimit: TAGS_COLLECTION_LIMIT,
    ...rest,
  }
}

export const useGetWebinars = (
  variables: VariablesType,
  options?: {
    enabled?: boolean
  },
  handleResponse = selectWebinars
) => {
  const enableRequest = options?.enabled ?? true
  const allowedUserPermissions = useGetAllowedContentPermissions()

  const queryParams = makeWebinarQueryVariables(variables)

  const response = useReactQueryResultAsApiState<
    GetWebinarsQuery,
    {
      featured: EventType | null
      upcoming: EventType[]
      replays: ContentDataType[]
    }
  >(
    useGetWebinarsQuery(
      {
        ...queryParams,
      },
      {
        enabled: enableRequest,
        refetchOnWindowFocus: false,
      }
    ),
    (data) => handleResponse(data, allowedUserPermissions.data),
    'Could not load webinarsQuery'
  )
  const loading =
    response === ApiStateEnum.LOADING || allowedUserPermissions.isLoading
  const error = isError(response)

  return compileResults(response, loading, error)
}

const selectWebinars = (
  data: GetWebinarsQuery,
  allowedPermissionsData: ContentPermissionsData
) => {
  const { featured, upcoming } = data

  const filteredFeatured = excludeByCategory(featured, category)

  const replays = [
    ...parseFromCollection<GetWebinarsQuery, CaisiqVideo>(
      data,
      'caisiqVideoCollection'
    ),
    ...parseFromCollection<GetWebinarsQuery, CaisiqVideoPlaylist>(
      data,
      'caisiqVideoPlaylistCollection'
    ),
  ]

  const featuredItem = selectFeaturedItem<EventType>({
    options: filteredFeatured.options as EventType[],
    fallback: filteredFeatured.fallback as EventType,
    userAccessData: allowedPermissionsData,
  })
  const featuredItemId = featuredItem?.sys.id
  const upcomingItems = filterAllowedPermissionData<EventType>(
    upcoming?.items as EventType[],
    allowedPermissionsData
  )
  const replayItems = filterAllowedPermissionData<ContentDataType>(
    replays,
    allowedPermissionsData
  )

  return {
    featured: featuredItem,
    upcoming:
      upcomingItems.length > 1
        ? excludeBySysId<EventType>(upcomingItems, featuredItemId)
        : upcomingItems,
    replays: sortContentData(
      excludeBySysId<ContentDataType>(replayItems, featuredItemId)
    ),
  }
}

const compileResults = (
  data: Data | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}

export const useGetWebinarsReplay = (variables: {
  date: string
  limit: number
  skip: number
  preview?: boolean
}) => {
  return useReactQueryResultAsApiState<
    GetWebinarsReplayQuery,
    ContentDataType[]
  >(
    useGetWebinarsReplayQuery({
      preview: false,
      ...variables,
      firmsLimit: FIRMS_COLLECTION_LIMIT,
    }),
    (data) => {
      const playlists = parseFromCollection<
        GetWebinarsReplayQuery,
        ContentDataType
      >(data, 'caisiqVideoPlaylistCollection')
      const videos = parseFromCollection<
        GetWebinarsReplayQuery,
        ContentDataType
      >(data, 'caisiqVideoCollection')
      return [...playlists, ...videos]
    },
    'Could not load eventsCollectionQuery'
  )
}
/**
 * @description Since we have mixed content types, we can't filter out non-webinar videos in the query
 */
const excludeByCategory = (
  data: GetWebinarsQuery['featured'],
  category: string
) => {
  const fallback = data?.items?.[0]?.fallback
  const options = data?.items?.[0]?.options?.items ?? []

  const optionWebinars = options.filter(
    (item) =>
      ((item?.__typename === 'CaisiqVideo' ||
        item?.__typename === 'CaisiqVideoPlaylist') &&
        item.category === category) ||
      item?.__typename === 'Event'
  )

  const fallbackWebinar =
    ((fallback?.__typename === 'CaisiqVideo' ||
      fallback?.__typename === 'CaisiqVideoPlaylist') &&
      fallback.category === category) ||
    fallback?.__typename === 'Event'
      ? fallback
      : null

  return {
    fallback: fallbackWebinar,
    options: optionWebinars,
  }
}
