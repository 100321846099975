import { capitalizeFirstLetter } from '@cais-group/equity/utilitarian'
import {
  APP_URL,
  callToActionEventsLink,
} from '@cais-group/homepage/util/common'
import { CloudinaryImage } from '@cais-group/shared/ui/cloudinary-image'
import type {
  ContentDataType,
  EventType,
  PressReleaseType,
  EventStatusKey,
} from '@cais-group/shared/util/contentful/types'
import type { UserProfileFirm } from '@cais-group/shared/util/graphql'

import {
  DEFAULT_CTA_TEXT,
  getType,
  getCtaText,
  getExternalDescriptionDetails,
  getHref,
  getPageType,
  getThumbnail,
  isEvent,
  isExternalContent,
  getContentOwnershipTag,
} from './helper'
import { type CtaAndIconType } from './types'

export function prepareEventCardProps<T>(event: EventType): T | null {
  if (!event) {
    return null
  }

  const baseProps = baseEventCardProps(event)

  return {
    ...baseProps,
    imageComponent: CloudinaryImage,
    includeTime: true,
    thumbnail: event.eventThumbnail
      ? {
          ...event.eventThumbnail[0],
          url: event.eventThumbnail[0].public_id,
        }
      : undefined,
  } as T
}

export function prepareBasicCardProps<T>(
  contentType: ContentDataType & {
    userFirms?: UserProfileFirm[]
  }
): T | null {
  if (!contentType) {
    return null
  }

  let icon = null

  if (contentType.__typename === 'CaisiqVideo' && contentType.icon?.length) {
    const iconProps = contentType.icon[0]
    icon = {
      height: iconProps.height,
      width: iconProps.width,
      url: iconProps.secure_url.replace('/f_auto', ''),
    }
  }

  if (isEvent(contentType)) {
    return prepareEventCardProps<T>(contentType)
  }

  const href = getHref(APP_URL, contentType)
  const thumbnail = getThumbnail(contentType)

  const externalContentDetails = isExternalContent(contentType)
    ? getExternalDescriptionDetails(contentType)
    : undefined

  const date =
    isExternalContent(contentType) && contentType.type === 'Fund'
      ? undefined
      : contentType.displayDate || ''

  const type = getType(contentType) as CtaAndIconType

  const contentOwnershipTag = getContentOwnershipTag(contentType)

  return {
    ...contentType,
    callToAction: getCtaText(type),
    href,
    date,
    thumbnail,
    id: contentType.sys?.id,
    externalContentDetails,
    // if thumbnail has public_id property, we know it's cloudinary
    imageComponent:
      thumbnail && thumbnail.public_id ? CloudinaryImage : undefined,
    icon,
    contentOwnershipTag: {
      name: contentOwnershipTag?.name,
      id: contentOwnershipTag?.id,
    },
  } as T
}

export function preparePressReleaseCardProps<T>(
  contentType: PressReleaseType,
  pressIndexLink: string
): T | null {
  if (!contentType) {
    return null
  }

  const href =
    contentType.externalNewsLink ??
    `${pressIndexLink}${contentType.linkedFrom?.pageCollection?.items[0]?.slug}`
  return {
    ...contentType,
    date: contentType.displayDate || '',
    callToAction: DEFAULT_CTA_TEXT,
    href,
    id: contentType.sys?.id,
  } as T
}

type BaseEventProps = {
  contentOwnershipTag: {
    id?: string | null
    name?: string | null
  }
  callToAction: string | null
  href: string | null
  id: string
  tag: string
}

export function baseEventCardProps(event: EventType): BaseEventProps | null {
  if (!event) {
    return null
  }

  const tag = getPageType({
    __typename: event.__typename,
    eventType: event.eventType,
    status: event.status || '',
  })

  const omitLink = tag === 'Completed'
  const type = (
    event.status
      ? `Event_${tag?.replace(' ', '_')}_${capitalizeFirstLetter(event.status)}`
      : `Event_${tag?.replace(' ', '_')}`
  ) as CtaAndIconType
  const contentOwnershipTag = getContentOwnershipTag(event)

  return {
    ...event,
    callToAction: omitLink ? null : getCtaText(type),
    contentOwnershipTag: {
      name: contentOwnershipTag?.name,
      id: contentOwnershipTag?.id,
    },
    href: omitLink
      ? null
      : callToActionEventsLink(event.status as EventStatusKey, event.link),
    id: event.sys?.id,
    tag,
  }
}
