import { type Tracking } from '@cais-group/equity/util/tracking-utils'
import { trackingLabels } from '@cais-group/homepage/util/tracking'
import {
  CardFeatured,
  prepareBasicCardProps,
} from '@cais-group/shared/ui/contentful/card'
import type { FeaturedCardProps } from '@cais-group/shared/ui/contentful/card'
import type { ContentDataType } from '@cais-group/shared/util/contentful/types'

type FeaturedResearchProps = Tracking & {
  featured: ContentDataType | null
}

export function Featured(props: FeaturedResearchProps) {
  const { featured, tracking } = props
  const featuredCardProps = featured
    ? prepareBasicCardProps<FeaturedCardProps>(featured)
    : null
  return featuredCardProps ? (
    <CardFeatured
      tracking={{
        sub_section: trackingLabels.subSection.FeaturedContent,
        ...tracking,
      }}
      {...featuredCardProps}
    />
  ) : null
}
