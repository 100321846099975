import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  ContentPermissionsData,
  parseFromCollection,
} from '@cais-group/shared/domain/contentful/api'
import type { ContentDataType } from '@cais-group/shared/util/contentful/types'
import {
  GetAllResearchTagCountsQuery,
  useGetAllResearchTagCountsQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { logError } from '@cais-group/shared/util/logging'

import { FILTERS } from '../constants'
import {
  filterAllowedPermissionData,
  makeResearchQueryVariables,
} from '../helper'

type Data = Record<string, number> | undefined

type Filters = Record<string, string[]>

export const useGetAllResearchTagCounts = (
  variables: {
    selectedFilters: Filters
    limit?: number
    searchText?: string
  },
  options?: {
    enabled?: boolean
    staleTime?: number
    cacheTime?: number
  }
) => {
  const allowedUserPermissions = useGetAllowedContentPermissions()

  const queryParams = makeResearchQueryVariables(
    variables.selectedFilters,
    variables.limit,
    FILTERS.research.is,
    variables.searchText
  )

  const response = useReactQueryResultAsApiState<
    GetAllResearchTagCountsQuery,
    Record<string, number>
  >(
    useGetAllResearchTagCountsQuery(queryParams, {
      enabled: options?.enabled,
      queryKey: ['all-research-tag-counts'],
      cacheTime: options?.cacheTime ?? 0, // because using the filters will affect the results
      staleTime: options?.staleTime ?? 0,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          message:
            error instanceof Error
              ? error.message
              : 'Could not load get all research tag counts query',
          error: error,
        })
      },
    }),
    (data) => selectAllResearch(data, allowedUserPermissions.data),
    'Could not load the "all research tag counts" query'
  )
  const loading =
    response === ApiStateEnum.LOADING || allowedUserPermissions.isLoading
  const error = isError(response)

  return compileResults(response, loading, error)
}

function compileResults(
  data: Data | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}

function selectAllResearch(
  data: GetAllResearchTagCountsQuery,
  allowedPermissionsData: ContentPermissionsData
) {
  const allowedResearch = filterAllowedPermissionData(
    [
      ...parseFromCollection<GetAllResearchTagCountsQuery, ContentDataType>(
        data,
        'caisiqArticleCollection'
      ),
      ...parseFromCollection<GetAllResearchTagCountsQuery, ContentDataType>(
        data,
        'caisiqVideoCollection'
      ),
      ...parseFromCollection<GetAllResearchTagCountsQuery, ContentDataType>(
        data,
        'caisiqVideoPlaylistCollection'
      ),
      ...parseFromCollection<GetAllResearchTagCountsQuery, ContentDataType>(
        data,
        'whitepaperCollection'
      ),
      ...parseFromCollection<GetAllResearchTagCountsQuery, ContentDataType>(
        data,
        'externalContentCollection'
      ),
    ],
    allowedPermissionsData
  )
  return tagCountsAvoidingDuplicatesWithinContentItem(allowedResearch)
}

function tagCountsAvoidingDuplicatesWithinContentItem(
  allResearchResults: ContentDataType[] | null | undefined
) {
  if (!allResearchResults) return {}

  const tagTotalsMap: Record<string, number> = {}

  for (const researchItem of allResearchResults) {
    const itemTags = researchItem?.tagsCollection?.items || []
    itemTags.reduce((itemTagIds, tag) => {
      const tagId = tag?.tagId

      if (tagId && itemTagIds.indexOf(tagId) === -1) {
        itemTagIds.push(tagId)
        if (tagTotalsMap[tagId]) {
          tagTotalsMap[tagId]++
        } else {
          tagTotalsMap[tagId] = 1
        }
      }
      return itemTagIds
    }, [] as string[])
  }

  return tagTotalsMap
}
