import type { ClientError } from 'graphql-request'

import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import { getPwsDomain } from '@cais-group/homepage/util/common'
import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  ContentPermissionsData,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import type {
  ContentDataType,
  CuratedContentDataType,
  HomeContentType,
  PressReleaseType,
} from '@cais-group/shared/util/contentful/types'
import {
  useGetFeaturedHomeQuery,
  useGetWebinarsQuery,
  useGetExternalContentQuery,
  useGetEventsQuery,
  useGetTutorialsPageContentQuery,
  useGetAllResearchQuery,
  useGetPressReleasesQuery,
  GetExternalContentQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import type {
  GetAllResearchQuery,
  GetEventsQuery,
  GetFeaturedHomeQuery,
  GetTutorialsPageContentQuery,
  GetWebinarsQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { logWarning } from '@cais-group/shared/util/logging'

import {
  CAROUSEL_ITEM_LIMIT,
  FEATURED_CONTENT_APP_ID,
  FIRMS_COLLECTION_LIMIT,
  PRESS_RELEASE_INDEX,
  TAGS_COLLECTION_LIMIT,
} from '../constants'
import {
  selectFirmCuratedContent,
  useGetFirmCuratedContent,
} from '../firm/use-get-firm-curated-content'
import { selectFeaturedItem, sortContentData } from '../helper'
import {
  excludeFeaturedItemAndExtract,
  excludeFeaturedItemSortAndExtract,
} from '../helper/exclude-featured-item-sort-and-extract'
import { makeHomeQueryParams } from '../helper/make-home-query-params'
import {
  selectEvents,
  selectPressReleases,
  selectProducts,
  selectResearch,
  selectTutorials,
  selectUpcomingWebinars,
  selectReplayWebinars,
  prepareCarouselContent,
} from '../helper/select-content-helpers'
import { selectLatestContent } from '../helper/select-latest-content'

type PageData = {
  featured: ContentDataType | null
  press: {
    pressIndexLink: string
    pressReleases: PressReleaseType[]
    error: boolean
  }
  content: HomeContentType & {
    loading: boolean
    error: boolean
  }
  curatedContent: {
    data: CuratedContentDataType[] | null
    loading: boolean
    error: boolean
  }
}

export const useGetHome = () => {
  const allowedUserPermissions = useGetAllowedContentPermissions()
  const response = useReactQueryResultAsApiState<
    GetFeaturedHomeQuery,
    ContentDataType | null
  >(
    useGetFeaturedHomeQuery(
      {
        featuredId: FEATURED_CONTENT_APP_ID,
        preview: previewService.enabled,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
        tagsLimit: TAGS_COLLECTION_LIMIT,
      },
      {
        refetchOnWindowFocus: false,
      }
    ),
    (data) =>
      selectFeaturedItem<ContentDataType>({
        options: data?.featured?.items[0]?.options?.items as ContentDataType[],
        fallback: data?.featured?.items[0]?.fallback as ContentDataType,
        userAccessData: allowedUserPermissions.data,
      }),
    'Could not load home query'
  )

  const loading =
    response === ApiStateEnum.LOADING || allowedUserPermissions.isLoading
  const error = isError(response)

  const featuredItemId = isData(response) ? response?.sys.id : undefined

  const { press, content, curatedContent } = useSelectHome({
    allowedPermissionsData: allowedUserPermissions.data,
    featuredItemId,
  })
  const featured = isData(response) ? response : null

  return compileResults(
    {
      featured,
      press,
      content,
      curatedContent,
    },
    loading,
    error
  )
}

const useSelectHome = ({
  allowedPermissionsData,
  featuredItemId,
}: {
  allowedPermissionsData: Omit<ContentPermissionsData, 'notFound'>
  featuredItemId?: string
}) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    retry: (failureCount: number) => failureCount < 1,
    onError: (error: ClientError) => {
      logWarning({ error, message: error.message })
    },
  }

  const {
    externalContentQueryParams,
    inPersonEventQueryParams,
    pressReleasesQueryParams,
    researchQueryParams,
    tutorialQueryParams,
    webinarQueryParams,
  } = makeHomeQueryParams()

  const curatedContent = useGetFirmCuratedContent()

  // TODO - do we want to logErrors individually on each query?
  const pressReleases = useGetPressReleasesQuery(
    pressReleasesQueryParams,
    queryOptions
  )

  const externalContent = useGetExternalContentQuery(
    externalContentQueryParams,
    queryOptions
  )

  const webinars = useGetWebinarsQuery(webinarQueryParams, queryOptions)
  const events = useGetEventsQuery(inPersonEventQueryParams, queryOptions)
  const tutorials = useGetTutorialsPageContentQuery(
    tutorialQueryParams,
    queryOptions
  )
  const research = useGetAllResearchQuery(researchQueryParams, queryOptions)

  const carouselContent = prepareCarouselContent(
    allowedPermissionsData,
    featuredItemId
  )

  const webinarsCarousel = {
    upcoming: carouselContent<GetWebinarsQuery>({
      filter: selectUpcomingWebinars,
      extract: excludeFeaturedItemAndExtract,
      data: webinars.data,
    }),
    replays: carouselContent<GetWebinarsQuery>({
      filter: selectReplayWebinars,
      extract: excludeFeaturedItemSortAndExtract,
      data: webinars.data,
    }),
  }

  const content = {
    products: {
      carousel: carouselContent<GetExternalContentQuery>({
        filter: selectProducts,
        extract: excludeFeaturedItemSortAndExtract,
        data: externalContent.data,
      }),
      error: externalContent.isError,
    },
    webinars: {
      carousel: [...webinarsCarousel.upcoming, ...webinarsCarousel.replays],
      error: webinars.isError,
    },
    research: {
      carousel: carouselContent<GetAllResearchQuery>({
        filter: selectResearch,
        extract: excludeFeaturedItemSortAndExtract,
        data: research.data,
      }),
      error: research.isError,
    },
    tutorials: {
      carousel: carouselContent<GetTutorialsPageContentQuery>({
        filter: selectTutorials,
        extract: excludeFeaturedItemSortAndExtract,
        data: tutorials.data,
      }),
      error: tutorials.isError,
    },
    events: {
      carousel: carouselContent<GetEventsQuery>({
        filter: selectEvents,
        extract: excludeFeaturedItemAndExtract,
        data: events.data,
      }),
      error: events.isError,
    },
  }

  // For the "Latest" tab combine the upcoming Events and Webinars
  // If there are not enough upcoming items, show the webinar replays
  const isEventsArray = Array.isArray(content.events.carousel)
  const isWebinarsArray = Array.isArray(content.webinars.carousel)
  let combinedEventsAndWebinars = []

  if (isEventsArray && isWebinarsArray) {
    combinedEventsAndWebinars = sortContentData(
      [...content.events.carousel, ...webinarsCarousel.upcoming],
      { order: 'asc' }
    )
  } else if (isEventsArray) {
    combinedEventsAndWebinars = content.events.carousel
  } else {
    combinedEventsAndWebinars = content.webinars.carousel ?? []
  }

  // Latest cards are selected from each content type and not sorted like the rest
  const latest = selectLatestContent([
    content.products.carousel,
    combinedEventsAndWebinars,
    content.research.carousel,
    content.tutorials.carousel,
  ]).slice(0, CAROUSEL_ITEM_LIMIT)

  return {
    press: {
      pressIndexLink: `${getPwsDomain()}${PRESS_RELEASE_INDEX}`,
      pressReleases: selectPressReleases(pressReleases.data).slice(
        0,
        CAROUSEL_ITEM_LIMIT
      ),
      error: pressReleases.isError,
    },
    curatedContent: {
      data: selectFirmCuratedContent(
        curatedContent.data,
        allowedPermissionsData
      ),
      loading: curatedContent.isLoading,
      error: curatedContent.isError,
    },
    content: {
      ...content,
      latest: {
        carousel: latest,
        error:
          externalContent.isError &&
          webinars.isError &&
          events.isError &&
          tutorials.isError &&
          research.isError,
      },
      loading:
        events.isLoading ||
        externalContent.isLoading ||
        pressReleases.isLoading ||
        research.isLoading ||
        tutorials.isLoading ||
        webinars.isLoading,
      error:
        events.isError &&
        externalContent.isError &&
        pressReleases.isError &&
        tutorials.isError &&
        research.isError &&
        webinars.isError,
    },
  }
}

const compileResults = (
  data: PageData | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}
