import {
  FIRMS_COLLECTION_LIMIT,
  sortContentData,
  type SortDirection,
} from '@cais-group/homepage/domain/contentful'
import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
} from '@cais-group/shared/domain/contentful/api'
import type { ContentDataType } from '@cais-group/shared/util/contentful/types'
import {
  CaisiqVideo,
  GetAllVideosQuery,
  useGetAllVideosQuery,
  CaisiqVideoOrder,
  CaisiqVideoPlaylistOrder,
} from '@cais-group/shared/util/graphql/mfe-contentful'

export type VideosResult = {
  caisiqVideoCollection: CaisiqVideo[]
}

type SortOptionsVideo =
  | CaisiqVideoOrder.DisplayDateAsc
  | CaisiqVideoOrder.DisplayDateDesc
type SortOptionsPlaylist =
  | CaisiqVideoPlaylistOrder.DisplayDateAsc
  | CaisiqVideoPlaylistOrder.DisplayDateDesc
type SortOrderOptions = SortOptionsVideo | SortOptionsPlaylist

type SortOrder = keyof {
  [K in SortOrderOptions as `${K}`]: SortOrderOptions
}

type QueryVariables = {
  limit?: number
  sortOrder?: SortOrder
  where?: Record<string, unknown>
  searchText?: string
}
type QueryOptions = { enabled?: boolean; queryKey?: string }

export const useGetAllVideos = (
  variables?: QueryVariables,
  options?: QueryOptions
) => {
  const sortOrder = variables?.sortOrder || 'displayDate_DESC'
  const queryParams = {
    preview: false,
    limit: variables?.limit || 60,
    whereVideo: variables?.where,
    wherePlaylist: variables?.where,
    orderVideo: sortOrder as SortOptionsVideo,
    orderPlaylist: sortOrder as SortOptionsPlaylist,
    firmsLimit: FIRMS_COLLECTION_LIMIT,
  }

  const response = useReactQueryResultAsApiState<
    GetAllVideosQuery,
    ContentDataType[]
  >(
    useGetAllVideosQuery(queryParams, {
      enabled: options?.enabled,
      queryKey: [options?.queryKey || 'all-videos'],
      refetchOnWindowFocus: false,
    }),
    (data) => selectAllVideos(data, sortOrder),
    'Could not load getAllVideosQuery'
  )
  const loading = response === ApiStateEnum.LOADING
  const error = isError(response)

  return compileResults(response, loading, error)
}

const compileResults = (
  data: ContentDataType[] | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}

export const selectAllVideos = (data: GetAllVideosQuery, sortOrder: string) => {
  const [, sortDirection] = sortOrder.split('_')
  const videos = data.caisiqVideoCollection?.items ?? []
  const playlists = data.caisiqVideoPlaylistCollection?.items ?? []
  const allVideos = [...videos, ...playlists] as ContentDataType[]

  return sortContentData(allVideos, {
    order: sortDirection as SortDirection,
  })
}
