import { camelCase } from 'lodash-es'
import { RefObject } from 'react'

import { Pill } from '@cais-group/equity/atoms/pill'

import { type PillTabsTypes } from '../utils/pill-config'

export const PillTabs = ({
  onHandlePillClick,
  activePillTab,
  pillLabels,
  pillRef,
}: {
  onHandlePillClick: (label: PillTabsTypes) => void
  activePillTab?: PillTabsTypes
  pillLabels: PillTabsTypes[]
  pillRef?: RefObject<HTMLUListElement>
}) => {
  return (
    <ul
      ref={pillRef}
      className="flex w-full gap-8 max-lg:order-3 max-md:overflow-auto lg:flex-wrap lg:gap-16 max-md:[&::-webkit-scrollbar]:hidden"
    >
      {pillLabels.map((label) => (
        <li key={label} id={camelCase(label)} className="w-fit shrink-0">
          <Pill
            color="primary"
            size="small"
            state={activePillTab === label ? 'active' : 'regular'}
            onClick={() => onHandlePillClick(label)}
            tracking={{
              click_type: 'Tab Navigation',
              item_name: label,
            }}
          >
            {label}
          </Pill>
        </li>
      ))}
    </ul>
  )
}
