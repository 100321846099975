import * as R from 'remeda'

import type { AtoZEntry } from './types'

const filterList = (list: AtoZEntry[]) => list.filter((x) => x?.term)

const sortBy = <T extends AtoZEntry>(list: T[]) =>
  R.sortBy(list, [(x) => x.term.toLowerCase(), 'asc'])

const groupAlphabetically = <T extends AtoZEntry>(list: T[]) =>
  R.groupBy(list, (x) => x.term.toUpperCase().charAt(0))

export const sortAndGroup = R.piped(filterList, sortBy, groupAlphabetically)
