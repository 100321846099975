import cx from 'classnames'

import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import { contentContainer } from '@cais-group/shared/ui/contentful/components'
import {
  type HomepageContentSection,
  type HomepageContentSectionFragment,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import { ComponentSection } from './components/component-section'
import { type ComponentSectionProps, type CustomContent } from './types'

export type LandingPageComponentProps = {
  section: HomepageContentSection &
    Pick<HomepageContentSectionFragment, 'componentId'>
} & CustomContent

export function LandingPageComponents({
  section,
  customContent,
}: LandingPageComponentProps) {
  if (!section) {
    return null
  }

  const sectionId = section.sys.id || 'unknown'
  const isSticky = section.componentId === 'Sticky-Download'
  return (
    <section
      key={sectionId}
      className={cx({
        'bg-neutral-0 has-[>:not(:empty)]:py-56 [&:nth-child(3)]:bg-neutral-100':
          !isSticky,
        'sticky inset-0 z-50': isSticky,
        'pt-0': section.removeTopPadding,
      })}
      {...mapTrackingKeysToDataAttributes({
        sub_section: (section as ComponentSectionProps).title || sectionId,
      })}
    >
      <div className={`${contentContainer} ${isSticky ? 'relative' : ''}`}>
        <ComponentSection
          key={`${sectionId}-ComponentSection`}
          {...(customContent && { customContent })}
          {...(section as ComponentSectionProps)}
        />
      </div>
    </section>
  )
}
