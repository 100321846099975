import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import { getPwsDomain } from '@cais-group/homepage/util/common'
import {
  ApiError,
  ApiStateEnum,
  ContentPermissionsData,
  isData,
  isError,
  parseFromCollection,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  ContentDataType,
  PressReleaseType,
} from '@cais-group/shared/util/contentful/types'
import {
  CuratedFilterGroup,
  GetFeaturedResearchContentQuery,
  useGetCuratedContentFiltersQuery,
  useGetFeaturedResearchContentQuery,
  useGetPressReleasesQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import { CAROUSEL_ITEM_LIMIT, FILTERS, PRESS_RELEASE_INDEX } from '../constants'
import {
  filterAllowedPermissionData,
  makeResearchQueryVariables,
  sortContentData,
} from '../helper'
import { makeFiltersFromCuratedFilterGroup } from '../helper/make-filters-from-curated-filter-group'
import { selectPressReleases } from '../press-release/getPressReleases'
import { FamilyType } from '../types'

type Data = ContentDataType[] | null

/*
  Avoid losing too many items when permissions are applied.
  An alternative is to also fetch the same number of items but have Contentful
  only return items with zero permissions.
  Then join, de-dupe, sort
*/
const OVER_FETCH_LIMIT = 2 * CAROUSEL_ITEM_LIMIT

const CAROUSEL_LENGTH = 5

type BandsType = {
  bandData: ContentDataType[] | null
  error: boolean
  loading: boolean
  filters: FamilyType
  label: string
}
export type FeaturedResearchPageType = {
  bands: BandsType[]
  press: {
    pressData: PressReleaseType[] | null
    error: boolean
    loading: boolean
    pressIndexLink: string
  }
}

export const useGetFeaturedResearchContent = (): FeaturedResearchPageType => {
  const allowedUserPermissions = useGetAllowedContentPermissions()

  const { data } = useGetCuratedContentFiltersQuery({
    id: 'homepage/research/featured',
    preview: previewService.enabled,
  })

  const pressReleasesResult = useReactQueryResultAsApiState(
    useGetPressReleasesQuery({
      limit: 12,
      preview: previewService.enabled,
    }),
    (data) => selectPressReleases(data),
    'Could not load press releases query'
  )

  const filterGroups = makeFiltersFromCuratedFilterGroup(
    (data?.curatedResearchContentCollection?.items[0]
      ?.curatedFilterGroupCollection
      ?.items as unknown as CuratedFilterGroup[]) ?? []
  )

  const response: BandsType[] = []

  const total =
    data?.curatedResearchContentCollection?.items[0]
      ?.curatedFilterGroupCollection?.total ?? 0

  Array.from({ length: CAROUSEL_LENGTH }).forEach((_, i) => {
    const filterGroup = filterGroups[i] ? filterGroups[i] : null

    const result = useReactQueryResultAsApiState(
      useGetFeaturedResearchContentQuery(
        makeResearchQueryVariables(
          filterGroup?.filter || {},
          OVER_FETCH_LIMIT,
          FILTERS.research.is
        ),

        {
          enabled: Boolean(filterGroup?.filter && i + 1 <= total),
          refetchOnWindowFocus: false,
        }
      ),
      (data) => selectFeaturedContent(data, allowedUserPermissions.data),
      `Could not load featured ${filterGroup?.label} query`
    )
    if (filterGroup?.filter) {
      response.push({
        label: filterGroup.label,
        filters: filterGroup.filter,
        ...compileResults(result),
      })
    }
  })

  return {
    bands: response,
    press: {
      pressData: isData(pressReleasesResult) ? pressReleasesResult : null,
      error: Boolean(isError(pressReleasesResult)),
      loading: pressReleasesResult === ApiStateEnum.LOADING,
      pressIndexLink: `${getPwsDomain()}${PRESS_RELEASE_INDEX}`,
    },
  }
}

const selectFeaturedContent = (
  data: GetFeaturedResearchContentQuery,
  allowedPermissionsData: ContentPermissionsData
) => {
  const allowed = filterAllowedPermissionData(
    [
      ...parseFromCollection<GetFeaturedResearchContentQuery, ContentDataType>(
        data,
        'articles'
      ),
      ...parseFromCollection<GetFeaturedResearchContentQuery, ContentDataType>(
        data,
        'whitepapers'
      ),
      ...parseFromCollection<GetFeaturedResearchContentQuery, ContentDataType>(
        data,
        'videos'
      ),
      ...parseFromCollection<GetFeaturedResearchContentQuery, ContentDataType>(
        data,
        'videoPlaylists'
      ),
      ...parseFromCollection<GetFeaturedResearchContentQuery, ContentDataType>(
        data,
        'externalContentCollection'
      ),
    ],
    allowedPermissionsData
  )

  return sortContentData(allowed).slice(0, CAROUSEL_ITEM_LIMIT)
}

const compileResults = (data: Data | ApiError | ApiStateEnum) => {
  return {
    bandData: isData(data) ? data : null,
    error: Boolean(isError(data)),
    loading: data === ApiStateEnum.LOADING,
  }
}
