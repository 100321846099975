import React from 'react'
import { useParams } from 'react-router-dom'
import { match, P } from 'ts-pattern'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { Markdown } from '@cais-group/equity/atoms/markdown'
import { CollapsibleSection } from '@cais-group/equity/labs/disclosure'
import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import { capitalizeFirstLetter } from '@cais-group/equity/utilitarian'
import { useMinimumDebouncedState } from '@cais-group/homepage/util/hook/use-minimum-debounced-state'
import { trackingLabels } from '@cais-group/homepage/util/tracking'
import { useGetFAQsByCategory } from '@cais-group/shared/domain/contentful/faq'
import { PageErrorSplash } from '@cais-group/shared/ui/contentful/components'
import { DocumentTitle } from '@cais-group/shared/util/document-title'

import { HomepageNoResults } from '../../../components/homepage-no-results'
import { ResultsCount } from '../../../components/results-count'

import { faqPermalink } from './faq-permalink'
import { useFaqCategories } from './layout'

export function FaqCategory() {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const { defaultCategoryId, faqSearchData } = useFaqCategories()
  const params = useParams()
  const { categoryId = defaultCategoryId, faqId } = params
  const [searchText] = useQueryParam('searchText', withDefault(StringParam, ''))

  const debouncedSearchText = useMinimumDebouncedState(searchText ?? '', 600)

  const { pageData: searchFAQsPageData, error: faqsError } = faqSearchData

  const { pageData: categoryFAQsPageData, error: categoryFAQsError } =
    useGetFAQsByCategory({
      variables: { id: categoryId },
      enabled: !debouncedSearchText,
    })

  React.useEffect(() => {
    const container = containerRef.current

    const handleFAQClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const thisFaqId = target.id || target.closest('summary')?.id

      if (!thisFaqId) {
        return
      }

      // Check the DOM for open state
      const isOpen = Boolean(
        container?.querySelector(`#${thisFaqId}`)?.closest('details')?.open
      )

      window.history.replaceState(
        {},
        '',
        faqPermalink({ categoryId, isOpen, thisFaqId })
      )
    }

    if (container) {
      container?.addEventListener('click', handleFAQClick)
    }
    return () => {
      container?.removeEventListener('click', handleFAQClick)
    }
  }, [categoryId, containerRef])

  return (
    <div
      ref={containerRef}
      className="space-y-24"
      {...mapTrackingKeysToDataAttributes({
        section: trackingLabels.subSection.FAQ, // Note: deliberately using a subSection name here
        sub_section: debouncedSearchText
          ? 'Search'
          : capitalizeFirstLetter(categoryId || ''),
      })}
    >
      {match({
        error: categoryFAQsError || faqsError,
        pageData: debouncedSearchText
          ? searchFAQsPageData
          : categoryFAQsPageData,
        categoryFAQsPageData,
      })
        .with({ error: true }, () => <PageErrorSplash.General />)
        .with(
          {
            error: false,
            pageData: P.not(P.nullish),
            categoryFAQsPageData: P.not(P.nullish),
          },
          ({ pageData, categoryFAQsPageData }) => (
            <>
              <DocumentTitle title={`${categoryFAQsPageData.title} - FAQs`} />

              {match({
                searchFAQsPageData,
                searchFAQsDataLength: searchFAQsPageData?.faqs.length,
                debouncedSearchText,
                categoryFAQsPageData,
              })
                .with(
                  {
                    searchFAQsPageData: P.nullish,
                    debouncedSearchText: P.when(
                      (debouncedSearchText) => !debouncedSearchText
                    ),
                  },
                  ({ categoryFAQsPageData }) => (
                    <h2 className="headline-l-strong text-brand-600">
                      {categoryFAQsPageData.title}
                    </h2>
                  )
                )
                .with(
                  {
                    searchFAQsPageData: P.not(P.nullish),
                    searchFAQsDataLength: P.number.gt(0),
                  },
                  ({ searchFAQsDataLength }) => (
                    <ResultsCount count={searchFAQsDataLength} />
                  )
                )
                .with(
                  {
                    searchFAQsDataLength: P.when((l) => !l),
                    debouncedSearchText: P.when(
                      (debouncedSearchText) => !!debouncedSearchText
                    ),
                    searchFAQsPageData: P.not(P.nullish),
                  },
                  ({ debouncedSearchText }) => (
                    <HomepageNoResults searchText={debouncedSearchText} />
                  )
                )
                .otherwise(() => null)}
              {pageData.faqs.map((faq) => (
                <CollapsibleSection
                  key={faq?.id}
                  id={faq?.id || ''}
                  defaultIsOpen={faq?.id === faqId}
                  variant="small"
                  title={faq?.faq || ''}
                >
                  <div className="space-y-16 p-24">
                    <Markdown options={{ wrapper: React.Fragment }}>
                      {faq?.answer || ''}
                    </Markdown>
                  </div>
                </CollapsibleSection>
              ))}
            </>
          )
        )
        .otherwise(() => null)}
    </div>
  )
}
