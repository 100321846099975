import { useState } from 'react'
import { match, P } from 'ts-pattern'

import { useGetTutorials } from '@cais-group/homepage/domain/contentful'
import { topPaddedContentContainer } from '@cais-group/homepage/ui/components'
import { ContentDisclaimer } from '@cais-group/homepage/ui/content-disclaimer'
import { useMinimumDebouncedState } from '@cais-group/homepage/util/hook/use-minimum-debounced-state'
import {
  PageErrorSplash,
  Main,
} from '@cais-group/shared/ui/contentful/components'
import type { ContentDataType } from '@cais-group/shared/util/contentful/types'
import { DocumentTitle } from '@cais-group/shared/util/document-title'

import { HomepageSearchInput } from '../../../components/homepage-search-input'

import { Tutorials } from './tutorials'

export function TutorialsIndex() {
  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useMinimumDebouncedState(searchText, 600)
  const { pageData, error, loading } = useGetTutorials({
    searchText: debouncedSearchText,
  })
  const hasFeatured = Boolean(pageData?.featured)

  const count = debouncedSearchText ? pageData?.tutorials?.length || 0 : 0

  const size =
    pageData?.tutorials?.length && hasFeatured && debouncedSearchText
      ? 'medium'
      : pageData?.tutorials?.length && !hasFeatured && debouncedSearchText
      ? 'small'
      : 'large'

  return (
    <>
      <ContentDisclaimer
        items={[
          ...(pageData?.tutorials || []),
          pageData?.featured || ({} as ContentDataType),
        ]}
        className={topPaddedContentContainer}
      />
      <Main>
        {match({ error })
          .with(
            {
              error: false,
            },
            () => (
              <HomepageSearchInput
                searchText={searchText}
                onSearch={setSearchText}
                count={count}
                size={size}
              />
            )
          )
          .otherwise(() => null)}
        {match({ pageData, error, loading })
          .with({ error: true }, () => <PageErrorSplash.General />)
          .with(
            { error: false, pageData: P.not(P.nullish) },
            ({ pageData }) => (
              <>
                <DocumentTitle title="Tutorials" />
                <Tutorials
                  featured={pageData.featured}
                  tutorials={pageData.tutorials}
                  debouncedSearchText={debouncedSearchText}
                  loading={loading}
                />
              </>
            )
          )
          .otherwise(() => null)}
      </Main>
    </>
  )
}
