import type { PropsWithChildren, ReactNode } from 'react'
import { useId } from 'react'
import { match, P } from 'ts-pattern'

import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import {
  CloudinaryImage,
  cloudinaryUrl,
} from '@cais-group/shared/ui/cloudinary-image'
import type { EventType } from '@cais-group/shared/util/contentful/types'

import { LiveEventItem } from './live-event-item'

const headingText = 'Experience CAIS Live In Person'

export function UpcomingLiveEvents(props: { events: EventType[] }) {
  const { events } = props
  const headingId = `heading-${useId()}`
  return (
    <UpcomingEventsContainer
      id={headingId}
      image={<HeroImage image={events[0]?.eventThumbnail?.[0]} />}
    >
      <h2
        className="headline-m-strong md:headline-l-strong text-balance"
        id={headingId}
      >
        {headingText}
      </h2>
      {match(props)
        .with(
          { events: P.when((events) => events && events.length > 0) },
          () => (
            <ol className="w-full space-y-24 md:space-y-16">
              {props.events.map((event) => (
                <li
                  key={event.sys.id}
                  className="flex items-center justify-between gap-8"
                >
                  <LiveEventItem event={event} />
                </li>
              ))}
            </ol>
          )
        )
        .otherwise(() => (
          <NoEvents />
        ))}
    </UpcomingEventsContainer>
  )
}

export function UpcomingEventsContainer(
  props: PropsWithChildren<{ image: ReactNode; id?: string }>
) {
  return (
    <section
      className="flex flex-col gap-32 md:gap-x-56 lg:flex-row"
      {...(props.id && { 'aria-labelledby': props.id })}
      {...mapTrackingKeysToDataAttributes({ sub_section: headingText })}
    >
      <div className="flex flex-col gap-32 lg:basis-1/2">{props.children}</div>
      <div className="order-first grid place-items-center lg:order-last lg:basis-1/2">
        {props.image}
      </div>
    </section>
  )
}

const NoEvents = () => <p>Registration opening soon</p>

export const FALLBACK_HERO_IMAGE_ID = 'pws/heroes/cais-live-hero-light_bx7whq'

const HeroImage = (props: { image: { public_id: string } | null }) => {
  const { image } = props
  const public_id = image?.public_id || FALLBACK_HERO_IMAGE_ID
  const src = cloudinaryUrl(public_id) ? public_id : null

  return src ? (
    <CloudinaryImage
      src={src}
      layout="fullWidth"
      alt="Upcoming CAIS Live events"
    />
  ) : null
}
