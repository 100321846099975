import { match, P } from 'ts-pattern'

import { CAROUSEL_ITEM_LIMIT } from '@cais-group/homepage/domain/contentful'
import { SkeletonCardList, ViewAll } from '@cais-group/homepage/ui/components'
import { APP_URL } from '@cais-group/homepage/util/common'
import {
  Card,
  prepareBasicCardProps,
} from '@cais-group/shared/ui/contentful/card'
import type { CardProps } from '@cais-group/shared/ui/contentful/card'
import {
  PageErrorSplash,
  Carousel,
  Section,
} from '@cais-group/shared/ui/contentful/components'

import { usePrepareInPersonEvents } from './hooks/use-prepare-in-person-events'

const viewAllText = 'View All'
const sectionTitle = 'In Case You Missed CAIS Summit 2023'

export function CaisLiveSummitReplays() {
  const { summitReplays } = usePrepareInPersonEvents()
  const { pageData, error, loading, queryString } = summitReplays

  const summitVideos = pageData?.slice(0, CAROUSEL_ITEM_LIMIT)

  return match({ summitVideos, error, loading })
    .with({ error: true }, () => <PageErrorSplash.Inline />)
    .with({ error: false, loading: true }, () => (
      <section aria-label="CAIS Live Summit replays">
        <SkeletonCardList testId="cais-summit-replays" />
      </section>
    ))
    .with(
      {
        error: false,
        loading: false,
        summitVideos: P.when(
          (summitVideos): summitVideos is Exclude<typeof pageData, null> =>
            Array.isArray(summitVideos) && summitVideos.length > 0
        ),
      },
      ({ summitVideos }) => {
        // TODO HP-448: May need to extend landing page model to include a label for each section
        return (
          <Section
            title={sectionTitle}
            count={summitVideos.length}
            endAdornment={
              <ViewAll
                href={`${APP_URL}/research/all-research?${queryString}`}
                text={viewAllText}
              />
            }
          >
            <Carousel
              items={summitVideos}
              itemMinWidth="medium"
              renderItem={({ item }) => {
                const props = prepareBasicCardProps<CardProps>(item)
                return props ? (
                  <Card testId="cais-summit-replays" {...props} />
                ) : null
              }}
            />
          </Section>
        )
      }
    )
    .otherwise(() => null)
}
